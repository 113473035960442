<template>
  <div>
    <!-- Admin -->
    <CCard>
      <div class="row pl-3 pr-3 pt-3">
        <div class="col-md-6">
          <h5>{{ $t("app.profile.title") }}</h5>
        </div>
        <div class="col-md-6">
          <CButton
            color="primary"
            class="float-right mt-0 mb-0 icon-btn"
            @click="openAdminPopup()"
          >
            <CIcon name="cil-pencil" />
          </CButton>
        </div>

        <CCardBody class="pb-4 pl-4 pr-4 pt-1" v-if="roleID == 5">
          <div class="row mt-0 mb-0">
            <div class="col-md-6"></div>
            <div class="col-md-6"></div>
          </div>
          <div class="row mb-3">
            <div
              style="font-size: 16px; font-weight: bold"
              class="col-md-3 font-weight-bold"
            >
              Full Name
            </div>
            <div class="col-md-9">
              {{ userProfile.name }}
            </div>
          </div>

          <div class="row mt-3 mb-3">
            <div
              style="font-size: 16px; font-weight: bold"
              class="col-md-3 font-weight-bold"
            >
              Email ID
            </div>
            <div class="col-md-9">
              {{ userProfile.email }}
            </div>
          </div>

          <div class="row mt-3 mb-3">
            <div
              style="font-size: 16px; font-weight: bold"
              class="col-md-3 font-weight-bold"
            >
              Password
            </div>
            <div class="col-md-9">************</div>
          </div>
        </CCardBody>

        <CCardBody class="pb-4 pl-4 pr-4 pt-1" v-else-if="roleID == 4">
          <div class="row mt-0 mb-0">
            <div class="col-md-6"></div>
            <div class="col-md-6"></div>
          </div>
          <div class="row mb-3">
            <div
              style="font-size: 16px; font-weight: bold"
              class="col-md-3 font-weight-bold"
            >
              Full Name
            </div>
            <div class="col-md-9">
              {{ userProfileWithRole.name }}
            </div>
          </div>

          <div class="row mt-3 mb-3">
            <div
              style="font-size: 16px; font-weight: bold"
              class="col-md-3 font-weight-bold"
            >
              Email ID
            </div>
            <div class="col-md-9">
              {{ userProfileWithRole.email }}
            </div>
          </div>

          <div class="row mt-3 mb-3">
            <div
              style="font-size: 16px; font-weight: bold"
              class="col-md-3 font-weight-bold"
            >
              Password
            </div>
            <div class="col-md-9">************</div>
          </div>

          <div class="row mt-3 mb-3">
            <div
              style="font-size: 16px; font-weight: bold"
              class="col-md-3 font-weight-bold"
            >
              Phone Number
            </div>
            <div class="col-md-9">{{ userProfileWithRole.phone_no }}</div>
          </div>
          <div class="row mt-3 mb-3">
            <div class="col-md-3" style="font-size: 16px; font-weight: bold">
              Designation
            </div>
            <div class="col-md-9">
              {{ userProfileWithRole.designation }}
            </div>
          </div>
          <div class="row mt-3 mb-3">
            <div
              style="font-size: 16px; font-weight: bold"
              class="col-md-3 font-weight-bold"
            >
              Company Name
            </div>
            <div class="col-md-9">{{ userProfileWithRole.company_name }}</div>
          </div>

          <div class="row mt-3 mb-3">
            <div
              style="font-size: 16px; font-weight: bold"
              class="col-md-3 font-weight-bold"
            >
              Company Address
            </div>
            <div class="col-md-9">
              {{ userProfileWithRole.company_address }}
            </div>
          </div>

          <div class="row mt-3 mb-3">
            <div
              style="font-size: 16px; font-weight: bold"
              class="col-md-3 font-weight-bold"
            >
              Country
            </div>
            <div class="col-md-9">{{ userProfileWithRole.country_name }}</div>
          </div>

          <div class="row mt-3 mb-3">
            <div
              style="font-size: 16px; font-weight: bold"
              class="col-md-3 font-weight-bold"
            >
              State
            </div>
            <div class="col-md-9">{{ userProfileWithRole.state }}</div>
          </div>

          <div class="row mt-3 mb-3">
            <div
              style="font-size: 16px; font-weight: bold"
              class="col-md-3 font-weight-bold"
            >
              City
            </div>
            <div class="col-md-9">{{ userProfileWithRole.city }}</div>
          </div>

          <div class="row mt-3 mb-3">
            <div
              style="font-size: 16px; font-weight: bold"
              class="col-md-3 font-weight-bold"
            >
              Pin Code
            </div>
            <div class="col-md-9">{{ userProfileWithRole.postal_code }}</div>
          </div>

          <div class="row mt-3 mb-3">
            <div
              style="font-size: 16px; font-weight: bold"
              class="col-md-3 font-weight-bold"
            >
              Region
            </div>
            <div class="col-md-9">{{ userProfileWithRole.region_name }}</div>
          </div>
        </CCardBody>
      </div>

      <b-modal
        id="mtbNameModal"
        title="Profile Update"
        ok-title="Save"
        cancel-title="Cancel"
        :size="roleID == 5 ? 'md' : 'lg'"
      >
        <div v-if="roleID == 5">
          <label class="font-weight-bold asterisk">Full Name </label>
          <p class="profile-name">
            <CInput
              placeholder="Full Name"
              type="text"
              required
              was-validated
              v-model="mtbName"
            />
            <show-errors
              :control="$v.mtbName"
              :field="$t('app.template.management.tempalte.name')"
            />
          </p>
          <label class="mt-2 asterisk font-weight-bold">Email ID </label>
          <p class="profile-name">
            <CInput
              placeholder="Email ID"
              type="text"
              v-model.trim="email"
              :disabled="true"
              was-validated
            />
            <show-errors
              :control="$v.email"
              :field="$t('app.template.management.tempalte.name')"
            />
          </p>
          <label class="mt-2 font-weight-bold">Password </label>
          <p class="profile-name">
            <CInput
              type="password"
              v-model="password"
              :disabled="true"
              placeholder="*************"
            />
            <show-errors
              :control="$v.password"
              :field="$t('app.template.management.tempalte.name')"
            />
          </p>
        </div>
        <div v-else-if="roleID == 4">
          <div class="row">
            <div class="col-xl-6 col-lg-6 col-md-6">
              <label class="font-weight-bold asterisk">Full Name </label>
              <p class="profile-name">
                <CInput
                  placeholder="Full Name"
                  type="text"
                  required
                  was-validated
                  v-model="managerObj.name"
                />
                <show-errors
                  :control="$v.managerObj.name"
                  :field="$t('app.template.management.tempalte.name')"
                />
              </p>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6">
              <label class="font-weight-bold"> Company Address </label>
              <p class="profile-name">
                <CInput
                  placeholder="  Company Address"
                  type="text"
                  v-model="managerObj.company_address"
                />
                <show-errors
                  :control="$v.managerObj.company_address"
                  :field="$t('app.template.management.tempalte.name')"
                />
              </p>
            </div>
          </div>

          <div class="row">
            <div class="col-xl-6 col-lg-6 col-md-6">
              <label class="asterisk font-weight-bold">Email ID </label>
              <p class="profile-name">
                <CInput
                  placeholder="Email ID"
                  type="text"
                  v-model.trim="managerObj.email"
                  :disabled="true"
                  was-validated
                />
                <show-errors
                  :control="$v.managerObj.email"
                  :field="$t('app.template.management.tempalte.name')"
                />
              </p>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6">
              <label class="font-weight-bold asterisk">Country </label>
              <p class="profile-name">
                <b-form-select
                  v-model="managerObj.country"
                  :options="optionCountries"
                  class=""
                  @change="selectCountry(managerObj.country)"
                ></b-form-select>
                <show-errors
                  :control="$v.managerObj.country"
                  :field="$t('app.template.management.tempalte.name')"
                />
              </p>
            </div>
          </div>

          <div class="row">
            <div class="col-xl-6 col-lg-6 col-md-6">
              <label class="font-weight-bold">Password </label>
              <p class="profile-name">
                <CInput
                  type="password"
                  v-model="managerObj.password"
                  :disabled="true"
                  placeholder="*************"
                />
                <show-errors
                  :control="$v.managerObj.password"
                  :field="$t('app.template.management.tempalte.name')"
                />
              </p>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6">
             <label class="asterisk font-weight-bold">
                State
              </label>
              <b-form-select v-model="managerObj.state" :options="stateList"
                  @change="selectRegions(managerObj.state)" class=""></b-form-select>
                <show-errors :control="$v.managerObj.state" :field="$t('app.accountRegistation.name')" />
            </div>

          </div>

          <div class="row">
            <div class="col-xl-6 col-lg-6 col-md-6">
              <label class="font-weight-bold"> Country Code </label>
              <p class="profile-name">
                <b-form-select
                  v-model="managerObj.country_code"
                  :options="countryCodeList"
                  class=""
                ></b-form-select>
                <show-errors
                  :control="$v.managerObj.country_code"
                  :field="$t('app.template.management.tempalte.name')"
                />
              </p>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6">
              <label class="font-weight-bold">City </label>
              <p class="profile-name">
                <CInput
                  placeholder="City"
                  type="text"
                  v-model="managerObj.city"
                />
                <show-errors
                  :control="$v.managerObj.city"
                  :field="$t('app.template.management.tempalte.name')"
                />
              </p>
            </div>
          </div>

          <div class="row">
            <div class="col-xl-6 col-lg-6 col-md-6">
              <label class="font-weight-bold"> Phone Number </label>
              <p class="profile-name">
                <CInput
                  v-model="managerObj.phone"
                  @keypress="onlyNumberWithOutDot"
                  v-on:keypress.e.prevent
                  @keydown.space.prevent
                  placeholder="Phone Number"
                  @blur="$v.managerObj.phone.$touch()"
                />
                <show-errors
                  :control="$v.managerObj.phone"
                  :field="$t('app.template.management.tempalte.name')"
                />
              </p>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6">
              <label class="font-weight-bold"> Pin Code </label>
              <p class="profile-name">
                <CInput
                  placeholder="Pin Code"
                  type="text"
                  v-model="managerObj.pin"
                />
                <show-errors
                  :control="$v.managerObj.pin"
                  :field="$t('app.template.management.tempalte.name')"
                />
              </p>
            </div>
          </div>

          <div class="row">
            <div class="col-xl-6 col-lg-6 col-md-6">
              <label class="font-weight-bold">Designation </label>
              <p class="profile-name">
                <CInput
                  placeholder="Designation"
                  type="text"
                  v-model="managerObj.designation"
                />
                <show-errors
                  :control="$v.managerObj.designation"
                  :field="$t('app.template.management.tempalte.name')"
                />
              </p>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6">
              <label class="asterisk font-weight-bold"> Region </label>
              <CInput v-model="managerObj.region_id" placeholder="Region" disabled />
                <show-errors :control="$v.managerObj.region_id"
                  :field="$t('app.accountRegistation.name')" />
            </div>
          </div>

          <div class="row">
            <div class="col-xl-6 col-lg-6 col-md-6">
              <label class="font-weight-bold"> Company Name </label>
              <p class="profile-name">
                <CInput
                  placeholder=" Company Name"
                  type="text"
                  v-model="managerObj.company_name"
                />
                <show-errors
                  :control="$v.managerObj.company_name"
                  :field="$t('app.template.management.tempalte.name')"
                />
              </p>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6"></div>
          </div>
        </div>

        <template v-slot:modal-footer="{}">
          <div class="col-lg-12 col-sm-12 col-12 p-0 row ml-0 mr-0">
            <div class="col-lg-12 col-12 col-sm-12 p-0 text-right">
              <b-button
                size="md"
                variant="secondary"
                class="mr-2 px-2"
                @click="cancelAll()"
                >Cancel
              </b-button>
              <b-button
                size="md"
                variant="primary"
                class="mr-2 px-2"
                v-if="roleID == 5"
                @click="submitAdminUser()"
                >Save
              </b-button>
              <b-button
                size="md"
                v-else-if="roleID == 4"
                variant="primary"
                class="mr-2 px-2"
                @click="submitAdminServiceUser()"
                >Save
              </b-button>
            </div>
          </div>
        </template>
      </b-modal>
    </CCard>
  </div>
</template>

<script>
import _ from "lodash";
import { mapGetters } from "vuex";
import {
  ADMIN_PROFILE_LIST,
  ADMIN_PROFILE_EDIT,
  MANAGER_REGION_LIST,
  MANAGER_PROFILE_UPDATE,
} from "@/store/actions.type";
import ShowErrors from "@/components/ShowErrors/ShowErrors.vue";
import { SET_REGION } from "@/store/mutations.type";
import { required, requiredIf } from "vuelidate/lib/validators";
import {
  multipleErrorMessages,
  emailValidation,
  newPasswordValidation,
  pinCodeValidation,
  phoneNoValidation,
  onlyNumberWithOutDot,
} from "@/shared/utils";
import constants from "@/constants/constants.js";
import { SERVICE_ZONAL_REGIONS } from "../../store/actions.type";
export default {
  name: "AdminProfile",
  components: { ShowErrors },
  data() {
    return {
      optionCountries:constants.optionCountries,
      onlyNumberWithOutDot: onlyNumberWithOutDot,
      optionRegionManagers: [{ value: "", text: "Select Region Name" }],
      countryCodeList: constants.countryCodeList,
      userProfile: {
        name: "",
        email: "",
        password: "",
        phone_no: "",
        company_name: "",
        company_address: "",
        country: "",
        state: "",
        city: "",
        postal_code: "",
        region_id: "",
        profile_photo: "",
        region_name: "",
        country_code: "",
        designation: "",
      },
      userProfileWithRole: {
        name: "",
        email: "",
        password: "",
        phone_no: "",
        company_name: "",
        company_address: "",
        country: "",
        state: "",
        city: "",
        postal_code: "",
        region_id: "",
        profile_photo: "",
        region_name: "",
        country: "",
        designation: "",
        country_name:""
      },
      mtbName: "",
      email: "",
      password: "",
      userObj: "",
      managerObj: {
        name: "",
        email: "",
        password: "",
        phone: "",
        company_name: "",
        company_address: "",
        country: "",
        state: "",
        city: "",
        pin: "",
        region_id: "",
        profile_photo: "",
        designation: "",
        country: "",
        country_code:'',
      },

       regionsStateFilter: {
        start: 0,
        limit: 50,
      },

       stateList: [{ value: "", text: "Select State" }],
      regionName: "",
      countryIdPayload: "",
      stateIdPayload: "",
    };
  },
  //validation for the fields
  validations: {
    mtbName: { required },
    email: { required, emailValidation },
    password: { newPasswordValidation },
    managerObj: {
      name: { required },
      email: { required, emailValidation },
      password: { newPasswordValidation },
      phone: { phoneNoValidation },
      company_name: {},
      company_address: {},
      country: {required},
      state: {required},
      city: {},
      pin: { pinCodeValidation },
      region_id: { required },
      profile_photo: {},
      designation: {},
      country: {
        required: requiredIf(function () {
          return this.managerObj.phone;
        }),
      },
    },
  },

  methods: {
    /**
     * @method openAdminPopup
     * @description It opens edit popup
     * @author @Jeevan Poojary
     */
    openAdminPopup() {
      if (this.roleID == 5) {
        this.mtbName =
          this.userProfile.name != null ? this.userProfile.name : "";
        this.email =
          this.userProfile.email != null ? this.userProfile.email : "";
      }
      if (this.roleID == 4) {
      
        this.managerObj.name =
          this.userProfileWithRole.name != null
            ? this.userProfileWithRole.name
            : "";
        this.managerObj.email =
          this.userProfileWithRole.email != null
            ? this.userProfileWithRole.email
            : "";
        this.managerObj.phone =
          this.userObj.phone_no != null ? this.userObj.phone_no : "";
        this.managerObj.country =
          this.userObj.country != null ? this.userObj.country : "";
        this.managerObj.state =
          this.userObj.state != null ? this.userObj.state : "";
        this.managerObj.city =
          this.userObj.city != null ? this.userObj.city : "";
        this.managerObj.pin =
          this.userObj.postal_code != null ? this.userObj.postal_code : "";
        this.managerObj.designation =
          this.userObj.designation != null ? this.userObj.designation : "";
        this.managerObj.country =
          this.userObj.country != null ? this.userObj.country : "";
        this.managerObj.country_code = this.userObj.country_code != null ? this.userObj.country_code : null;           
               
              if (this.userObj.country == 0) {
                this.userProfileWithRole.country_name = "India";
              } else if (this.userObj.country == 1) {
                this.userProfileWithRole.country_name = "Sri Lanka";
              } else if (this.userObj.country == 2) {
                this.userProfileWithRole.country_name = "Bangladesh";
              }


        this.managerObj.company_name =
          this.userObj.company_name != null ? this.userObj.company_name : "";
        this.managerObj.company_address =
          this.userObj.company_address != null
            ? this.userObj.company_address
            : "";
        this.managerObj.region_id =
          this.userObj.region_id != null ? this.userObj.region_id : "";
      }

     if(this.roleID == 4){
          this.getAllState(this.managerObj.country);
          this.getAllRegionsData(this.managerObj.state)
     }
    
            this.isLoadingUserProfileedit = this.$loading.show();

      this.$bvModal.show("mtbNameModal");
                this.isLoadingUserProfileedit.hide();

    },
    /**
     * @method getUserDetail
     * @description It returns user detail in obj
     * @author @Jeevan Poojary
     */
    async getUserDetail() {
      this.isLoadingUserProfile = this.$loading.show();
      let data = await this.$store.dispatch(ADMIN_PROFILE_LIST);
      if (this.roleID == 5) {
        this.userProfile = data;
        this.userProfile.name = data.name;
        this.userProfile.email = data.email;
      }

      if (this.roleID == 4) {
        this.userObj = data.user_profile ? data.user_profile : "";
        if (this.userObj != null) {
          this.userProfileWithRole.name = data.name;
          this.userProfileWithRole.email = data.email;

          this.userProfileWithRole.company_name =
            this.userObj.company_name != null ? this.userObj.company_name : "-";

          this.userProfileWithRole.designation =
            this.userObj.designation != null ? this.userObj.designation : "-";
          this.userProfileWithRole.company_address =
            this.userObj.company_address != null
              ? this.userObj.company_address
              : "-";
          this.userProfileWithRole.country =
            this.userObj.country != null ? this.userObj.country : "-";

          if (this.userProfileWithRole.country == 0) {
            this.userProfileWithRole.country_name = "India";
          } else if (this.userProfileWithRole.country == 1) {
            this.userProfileWithRole.country_name = "Sri Lanka";
          } else if (this.userProfileWithRole.country == 2) {
            this.userProfileWithRole.country_name = "Bangladesh";
          }
          this.userProfileWithRole.state =
            this.userObj.state != null ? this.userObj.state : "-";
          this.userProfileWithRole.city =
            this.userObj.city != null ? this.userObj.city : "-";
          this.userProfileWithRole.postal_code =
            this.userObj.postal_code != null ? this.userObj.postal_code : "-";
          this.userProfileWithRole.phone_no =
            this.userObj.phone_no != null ? this.userObj.phone_no : null;

          this.userProfileWithRole.country_code =
            this.userObj.country_code != null
              ? this.userObj.country_code
              : null;
          if (
            this.userProfileWithRole.country_code != null &&
            this.userProfileWithRole.phone_no == null
          ) {
            this.userProfileWithRole.phone_no =
              this.userProfileWithRole.country_code;
          } else if (
            this.userProfileWithRole.country_code == null &&
            this.userProfileWithRole.phone_no != null
          ) {
            this.userProfileWithRole.phone_no = data.phone_no;
          } else if (
            this.userProfileWithRole.country_code == null &&
            this.userProfileWithRole.phone_no == null
          ) {
            this.userProfileWithRole.phone_no = "-";
          } else {
            this.userProfileWithRole.phone_no =
              this.userProfileWithRole.country_code +
              " " +
              this.userProfileWithRole.phone_no;
          }
          let regionObj = data.region_name;
          this.userProfileWithRole.region_name =
            regionObj.name != null ? regionObj.name : "-";
          this.$store.commit(SET_REGION, regionObj);
          this.countryIdPayload = this.userProfileWithRole.country;
           this.userProfileWithRole.region_name =
            regionObj.name != null ? regionObj.name : "-";
        }
      }
      setTimeout(() => {
        this.isLoadingUserProfile.hide();
      }, 1200);
    },

    /**
     * @method redirectToEdit
     * @description It redirects to profile edit screen
     * @author @Jeevan Poojary
     */
    redirectToEdit() {
      this.$router.push({
        name: "プロフィール ＞　編集",
        params: { id: this.userProfile.id },
      });
    },

    /**
     * @method cancelAll
     * @description It will cancel all the data
     * @author @Jeevan Poojary
     */
    cancelAll() {
      this.email = "";
      this.mtbName = "";
      this.password = "";
      this.managerObj.name = "";
      this.managerObj.email = "";
      this.managerObj.phone = "";
      this.managerObj.company_name = "";
      this.managerObj.company_address = "";
      this.managerObj.state = "";
      this.managerObj.city = "";
      this.managerObj.pin = "";
      this.managerObj.region_id = "";
      this.managerObj.designation = "";
      this.managerObj.country = "";
      this.$bvModal.hide("mtbNameModal");
    },

    /**
     * @method submitAdminServiceUser
     * @description Tt will save admin user data
     * @author @Jeevan Poojary
     */
    async submitAdminServiceUser() {
      
      this.$v.managerObj.$touch();
      if (!this.$v.managerObj.$invalid) {
        this.managerObj.id = this.userProfile.id;
         this.managerObj.region_id = this.regionName;
        this.isLoadingSubmitUser = this.$loading.show();
        await this.$store
          .dispatch(MANAGER_PROFILE_UPDATE, this.managerObj)
          .then((data) => {
            if (_.get(data, "data.success", false)) {
              this.isLoadingSubmitUser.hide();
              this.$toast.removeAll();
              this.$toast.success({
                message: _.get(data, "data.message", ""),
              });
              this.$bvModal.hide("mtbNameModal");

              this.getUserDetail();
            } else {
              this.isLoadingSubmitUser.hide();
              this.$bvModal.hide("mtbNameModal");
              this.getUserDetail();

              let message = _.get(data, "message", "");
              if (message.length > 0) {
                this.$toast.error({
                  message: message,
                });
              } else {
                this.$toast.removeAll();
                let messageObj = _.get(data, "message", "");
                multipleErrorMessages(this.$toast, messageObj);
              }
            }
          });
      }
    },

    /**
     * @method submitAdminUser
     * @description Tt will save admin user data
     * @author @Jeevan Poojary
     */
    async submitAdminUser() {
      this.$v.$touch();
      if (
        !this.$v.email.$invalid &&
        !this.$v.password.$invalid &&
        !this.$v.mtbName.$invalid
      ) {
        let payload = {
          email: this.email,
          id: this.userProfile.id,
          name: this.mtbName,
          password: this.password,
        };
        this.isLoadingAdmin = this.$loading.show();
        await this.$store.dispatch(ADMIN_PROFILE_EDIT, payload).then((data) => {
          if (_.get(data, "data.success", false)) {
            this.isLoadingAdmin.hide();
            this.$toast.removeAll();
            this.$toast.success({
              message: _.get(data, "data.message", ""),
            });
            this.$bvModal.hide("mtbNameModal");

            this.getUserDetail();
          } else {
            this.isLoadingAdmin.hide();
            this.$bvModal.hide("mtbNameModal");
            this.getUserDetail();

            let message = _.get(data, "message", "");
            if (message.length > 0) {
              this.$toast.error({
                message: message,
              });
            } else {
              this.$toast.removeAll();
              let messageObj = _.get(data, "message", "");
              multipleErrorMessages(this.$toast, messageObj);
            }
          }
        });
      }
    },
    async selectRegions(state) {
      this.regionName = "";
      this.managerObj.region_id = "";
       if(this.managerObj.state != ""){
      this.isLoadingStateregion = this.$loading.show();
      let data = await this.$store.dispatch(SERVICE_ZONAL_REGIONS, {
        filters: this.regionsStateFilter,
        country: this.countryIdPayload,
        search: state,
      });
      this.isLoadingStateregion.hide();
      if (_.get(data, "success", true)) {
        let stateDataList = [];
        stateDataList = _.get(data.data, "model.list", []);
        if (stateDataList.length > 0) {
          this.regionName = stateDataList[0].region_id;
          this.managerObj.region_id = stateDataList[0].region_name;
        }
      } else {
        let message = _.get(data, "message", "");
        if (message.length > 0) {
          this.$toast.error({
            message: message,
          });
        } else {
          this.$toast.removeAll();
          let messageObj = _.get(data, "message", "");
          multipleErrorMessages(this.$toast, messageObj);
        }
      }
       }else{
        this.managerObj.region_id = "";
       }
    },

    async getAllRegionsData(state) {
      let data = await this.$store.dispatch(SERVICE_ZONAL_REGIONS, {
        filters: this.regionsStateFilter,
        country: this.countryIdPayload,
        search: state,
      });

      if (_.get(data, "success", true)) {
        let stateDataList = [];
        stateDataList = _.get(data.data, "model.list", []);
        if (stateDataList.length > 0) {
          this.regionName = stateDataList[0].region_id;
          this.managerObj.region_id = stateDataList[0].region_name;
        }
      } else {
        let message = _.get(data, "message", "");
        if (message.length > 0) {
          this.$toast.error({
            message: message,
          });
        } else {
          this.$toast.removeAll();
          let messageObj = _.get(data, "message", "");
          multipleErrorMessages(this.$toast, messageObj);
        }
      }
    },

       /**
     * @method selectCountry
     * @description on select of country it list all the state
     * @author @Jeevan Poojary
     */
    async selectCountry(id) {
      if(this.managerObj.country != ""){
        this.countryIdPayload = id;

      this.stateList = [{ value: "", text: "Select State" }];

      let stateCount = this.stateList.length;
      this.regionName = "";
      this.managerObj.region_id = "";
      this.isLoadingCountry = this.$loading.show();
      let data = await this.$store.dispatch(SERVICE_ZONAL_REGIONS, {
        filters: this.regionsStateFilter,
        country: this.countryIdPayload,
      });
      this.isLoadingCountry.hide();
      if (_.get(data, "success", true)) {
        let stateDataList = [];
        stateDataList = _.get(data.data, "model.list", []);

        let stateItems = [];
        let i;
        for (i = 0; i < stateDataList.length; i++) {
          stateItems.push({
            text: stateDataList[i].state_name,
            value: stateDataList[i].state_name,
          });
        }
        this.stateList = _.concat(...this.stateList, stateItems);

        if (this.stateList.length != stateCount) {
          this.managerObj.state = '';
        }

      } else {
        let message = _.get(data, "message", "");
        this.stateList = [];
        if (message.length > 0) {
          this.$toast.error({
            message: message,
          });
        } else {
          this.$toast.removeAll();
          let messageObj = _.get(data, "message", "");
          multipleErrorMessages(this.$toast, messageObj);
        }
      }
      }else{
        this.stateList = [{ value: "", text: "Select State" }];
        this.managerObj.state = "";
        this.managerObj.region_id = "";
      }
      
    },

    
    async getAllState(id) {
      let data = await this.$store.dispatch(SERVICE_ZONAL_REGIONS, {
        filters: this.regionsStateFilter,
        country: id,
      });
      if (_.get(data, "success", true)) {
        let stateDataList = [];
        stateDataList = _.get(data.data, "model.list", []);

        let stateItems = [];
        let i;
        for (i = 0; i < stateDataList.length; i++) {
          stateItems.push({
            text: stateDataList[i].state_name,
            value: stateDataList[i].state_name,
          });
        }
        this.stateList = _.concat(...this.stateList, stateItems);

      }
    },
  },
  async created() {
    this.getUserDetail();
    let dropdownRole = await this.$store.dispatch(MANAGER_REGION_LIST);
    let userOptions = [];
    let i;
    for (i = 0; i < dropdownRole.length; i++) {
      userOptions.push({
        text: dropdownRole[i].name,
        value: dropdownRole[i].id,
      });
    }
    this.optionRegionManagers = _.concat(
      ...this.optionRegionManagers,
      userOptions
    );
  },
  computed: {
    ...mapGetters(["user"]),
    roleID() {
      return this.$store.state.auth.roleID;
    },
    regionName() {
      return this.$store.state.auth.regionName;
    },
  },
};
</script>
<style>
.profile-name .form-group {
  margin-bottom: 0rem !important;
}
.icon-btn {
  min-width: 40px !important;
}
</style>
